.header-search-bar::placeholder {
  color: #ffffff99 !important;
  font-size: 20px !important;
}

.close-button {
  border-radius: 50%;
}

.close-button:hover {
  background: #ffffff50;
}

@media screen and (max-width: 767px) {
  .header-search-bar::placeholder {
    font-size: 15px !important;
  }
}

.placeholder-white::placeholder {
  color: white;
}

.placeholder-white {
  text-decoration: "none";
}