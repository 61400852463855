.near-me:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

.social-media-flinks p a {
  border-radius: 50%;
  opacity: 0.8;
  background-color: #ffffff20;
  backdrop-filter: blur(20px);
}

.social-media-flinks p a:hover {
  opacity: 1;
}
.footer-copyright, .footer-copyright a{
  font-family: "proxima_nova_rgregular";
}
.footer-heading-btn button{
  margin-left: 15px;
  color: white;
  font-size: medium;
  font-family: "proxima_nova_rgbold";
  background-color: transparent;
  border-style: hidden;
}
.footer-heading-btn{
  margin-right: 60px;
}
.footer-heading h2{
  font-family: "proxima_nova_rgbold";
  letter-spacing: 1px;
  margin-left: 68px; 
}
.mobile-heading{
  font-family: "proxima_nova_rgbold";
  letter-spacing: 1px;
  display: none;
  font-size: 25px;
}
@media (max-width: 628px) {
  .footer-heading-btn button{
    display: none;
  }
  .footer-heading h2{
  display: none;
  }
  .mobile-heading{
    display: block;
  }
}