.landing-page-upper {
  background-color: #3cb6d3;
  color: white;
}
.landing-page-upper .content {
  height: 220px;
}

.locations img {
  height: 13rem;
  width: 100%;
  object-fit: cover;
  opacity: 0.9;
  transition: box-shadow 300ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(63, 61, 61, 0.4);
}

.locations img:hover {
  opacity: 1;
}

.locations h3 {
  text-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
}

.landing-page section {
  min-height: 600px;
}

.places-search-bar .input-container {
  border-radius: 0.4rem;
  box-shadow: 0 0.1rem 0.7rem rgb(0 0 0 / 5%);
}

.places-search-bar input::placeholder,
.post-property input::placeholder {
  font-size: 13px;
  color: #5c6f73;
}
.main-heading {
  font-size: clamp(2.5em, 6vw, 3.5em);
  /* font-size: 52px; */
}

.css-button-retro--green {
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border: 1px solid #000;
  color: #fff;
  background: transparent;
}
.css-button-retro--green:hover:after {
  top: 0;
  left: 0;
}
.css-button-retro--green:after {
  content: "";
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 5px;
  left: 5px;
  transition: 0.2s;
  background-color: #3cb6d3;
}

.css-button-community {
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
}

.dedicated-text:first-letter {
  font-size: 40px !important;
}

.landing-page-content .top,
.landing-page-content .bottom {
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.landing-page-content .top {
  top: 0;
  border-top: 1px solid;
}

.landing-page-content .bottom {
  bottom: 0;
  border-bottom: 1px solid;
}

.landing-page-content .left {
  left: 0;
  border-left: 1px solid;
}

.landing-page-content .right {
  right: 0;
  border-right: 1px solid;
}
