.heart-animation {
  width: 80px;
  height: 100px;
  background: url("https://cssanimation.rocks/images/posts/steps/heart.png");
  background-position: 0 0;
  transition: background-position 1s steps(28);
  transition-duration: 0s;
  top: -20px;
  right: 0px;
}

.heart-animation.active {
  transition-duration: 1s;
  background-position: -2800px 0;
}
