.profile-options-link {
  border-radius: 99px;
  color: #5c6f73 !important;
}
.profile-options-link.active {
  background-color: #ffffff;
  color: #1c5663 !important;
}

@media screen and (max-width: 767px) {
  .profile-options-link.active {
    border-bottom: 1px solid #1c5663;
    border-radius: 0px;
  }
}
