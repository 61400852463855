.cslider__track,
.cslider__range {
  height: 14px;
  border-radius: 30px;
}

.cslider__track {
  background-color: #3cb6d3;
  border-radius: 60px;
}

.cslider__range {
  background-color: #3cb6d3;
  border-radius: 60px;
}

.cthumb,
.cthumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.cthumb {
  pointer-events: none;
  height: 14px;
  outline: none;
}

.cthumb::-webkit-slider-thumb {
  z-index: 2 !important;
  background-color: white !important;
  border: 1px solid #3cb6d3 !important;
  border-radius: 50%;
  cursor: pointer;
  height: 30px !important;
    width: 30px !important;
  pointer-events: all;
  position: relative;
  bottom: 8px;
  }
