.images-container .del-button {
  top: 20px;
  right: 30px;
  cursor: pointer;
  backdrop-filter: blur(20px);
}

.images-container img:hover {
  border-color: #3cb6d3 !important;
  cursor: grab;
}

.upload-videos .post-property-img-input::after {
  content: "Add Videos";
}
