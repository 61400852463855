.mySwiper {
  width: 100%;
  height: clamp(250px, 45vh, 350px);
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  position: relative;
}

.swiper-slide-img {
  height: 100%;
  width: 100%;
}

.swiper-slide-img img {
  width: 100%;
  height: 100%;
  /* object-fit: contain !important; */
}

.features-chip {
  width: fit-content;
  height: fit-content;
  border-radius: 99px !important;
}

.features-chip2 {
  background-color: #1c566309;
  width: fit-content;
  height: fit-content;
  border-radius: 99px !important;
}

.apartment-desc span {
  background-color: transparent !important;
  color: #1c5663 !important;
}

.chip-selected {
  background-color: #1c566309;
}

.flatpickr-months .flatpickr-month,
.flatpickr-innerContainer .flatpickr-weekdays,
.flatpickr-innerContainer .flatpickr-weekday,
.flatpickr-days .flatpickr-day.selected {
  background-color: #3cb6d3 !important;
  color: white;
}

.fc-5c {
  color: #5c6f73;
}

.fc-75 {
  color: #758d91;
}

.fc-39 {
  color: rgb(39, 120, 138);
}

/* Image gallery */
.image-grid {
  height: 100% !important;
  --num-cols: 3;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
}

.image-grid > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-grid-col-2 {
  grid-column: span 2;
}

.image-grid-row-2 {
  grid-row: span 2;
}

/* Anything udner 1024px */
@media screen and (max-width: 1024px) {
  .image-grid {
    --num-cols: 2;
    /* --row-height: 200px; */
  }
}
