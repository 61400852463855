.modal {
  background-color: #00000040 !important;
  box-shadow: none;
  min-height: 100vh;
  display: flex;
  padding-right: 0 !important;
}

.modal-body {
  padding: 0 !important;
  width: 375px;
  height: auto;
  
}

.modal-dialog {
  min-width: 100vw !important;
}

.modal-content {
  width: 30% !important;
  margin: auto !important;
  background-color: transparent !important;
  padding: 0 !important;
  border: none !important;
}

.modal-body-container {
  border-radius: 8px;
  background-color: #f6f8f9;
}

.modal-body-container .right {
  min-height: 200px !important;
  border-radius: 8px;
}

.you-are-item {
  border-color: #3cb6d3 !important;
  background-color: #3cb6d320 !important;
}

@media screen and (max-width: 720px) {
  .modal-content {
    width: 90% !important;
    margin-top: 35px !important;
  }

  .modal {
    padding: 0 !important;
  }

  .modal-dialog {
    margin: 0 !important;
  }
}

.auth-otp-input {
  padding-left: 5px !important;
  letter-spacing: 40px;
  border: 0;
  background-image: linear-gradient(
    to left,
    #00000035 69%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 303px !important;
  overflow: hidden;
}

.auth-otp-input.filled {
  caret-color: transparent !important;
}
