.scrollable-col {
    max-height: calc(100vh - 20px); /* Adjust height according to your needs */
    overflow-y: auto;
  }
  
  .position-sticky {
    position: sticky;
    top: 0;
  }

  .popUpModalList {
    padding: 4px;
    font-weight: 600;
    opacity: 0.7;
    font-family: proxima_nova_rgregular;
  }

  .popUpModalList:hover {
      cursor: pointer;
      background-color: gray;
      color: white;
      border-radius: 4px;
  }
/* Target the scrollbar */
.comment-box-scroll::-webkit-scrollbar {
  width: 3px; /* Set width of the scrollbar */
}

/* Track */
.comment-box-scroll::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

/* Handle */
.comment-box-scroll::-webkit-scrollbar-thumb {
  background: gray; /* Color of the scrollbar handle */
  border-radius: 5px; /* Round the corners of the scrollbar handle */
  opacity: 0.4;
}

/* Handle on hover */
.comment-box-scroll::-webkit-scrollbar-thumb:hover {
  background: black; /* Color of the scrollbar handle on hover */
  cursor: pointer;
}

.post-popup::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Optionally, you can also hide scrollbar track */
.post-popup::-webkit-scrollbar-track {
  background: transparent;
}

/* Optionally, you can also hide scrollbar thumb */
.post-popup::-webkit-scrollbar-thumb {
  background: transparent;
}
