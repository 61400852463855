.custom-input-div input {
  outline: none !important;
  border: none !important;
}
.custom-input-div label {
  transition: all 0.3s ease-in;
  transform: translateY(-7px);
  pointer-events: none;
}
.custom-input-div input:focus + label {
  transform: translateY(-14px);
}
