.modalWrapper{
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100vh;
   background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
   z-index: 999; /* Ensure it's on top of other content */
}
.loginModal {
    width: 400px;
    height: fit-content;
    background: #fff;
    margin: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    padding-top: 25px;
    border-radius: 8px !important;
    box-shadow: 0px 8px 12px 0px rgba(31,92,163,0.2);
    background-size: contain;
    z-index: 1000;
    cursor: pointer;
    font-family: "proxima_nova_rgregular";
}

.heading {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    color: #091E42;
    display: block;
    text-align: left;
    font-family: "proxima_nova_rgbold";
 }

 .heading2{
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #42526E;
    display: block;
    text-align: left;
    margin-bottom: 24px;
    margin-top: -12px;
    font-family: "proxima_nova_rgregular";
 }

 .loginModal button{
    background: #0078DB;
    cursor: not-allowed;
    opacity: .6;
    width: 100%;
    padding: 12px 0px;
    font-weight: 600;
    border-style: hidden;
    color: white;
    border-radius: 5px;
    margin-top: 102px;
    font-family: "proxima_nova_rgregular";
    font-size: larger;
    letter-spacing: 1px;
 }

 .loginModal button:hover{
   opacity: 1;
   transition: all;
 }

 .loginModal input[type="number"], .loginModal input[type="tel"], .loginModal input[type="text"], .phoneInput{
    border-bottom: hidden;
    border: 2px solid #EBECF0;
    width: 100%;
    position: relative;
    height: 52px;
    padding: 5px;
    border-radius: 8px;
    font-family: "proxima_nova_rgregular";
 }
 .phoneNumber{
   font-family: "proxima_nova_rgregular";
 }
 .changeNumButton{
   font-family: "proxima_nova_rgregular";
   color: #0078DB;
   font-weight: 700;
   font-size: small;
 }
 .loginModal input[type=number]:not(.browser-default):focus:not([readonly]), .loginModal input[type=text]:not(.browser-default):focus:not([readonly]), .loginModal input[type=tel]:not(.browser-default):focus:not([readonly])  {
    border-bottom: hidden;
    border: 2px solid #0078DB;
 }

 .loginModal > .input_div{
   margin-top: -10px;
 }

 .loginModal > .input_div > #OtpInput {
    box-shadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)";
    border: 2px solid #EBECF0;
    border-radius: 8px;
    color: black; 
    text-align: center;
    font-family: "proxima_nova_rgregular";
 }
 .loginModal > .input_div > #OtpInput:focus {
    border: 2px solid #0078DB;
  }
  .close_modal{
   position: absolute;
   top: 5px;
   right: 10px;
   color: gray;
   font-family: "proxima_nova_rgregular";
   font-size: 20px;
   font-weight: 600;
  }
  .close_modal:hover{
    color: black;
    transition: all;
   }
  @media (max-width: 768px) {
   .loginModal{
      width: 94vw;
   }
   .loginModal button{
     margin-top: 70px;
     padding: 8px 0;
   }
  }