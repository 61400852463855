:root {
  --swiper-navigation-size: 20px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: #3cb6d3;
  font-weight: bolder;
}

@font-face {
  font-family: 'HelveticaNeueMedium';
  src: url('./HelveticaNeueMedium.otf') format('opentype');
}
@font-face {
  font-family: 'proximanovabold';
  src: url('./proximanova-bold.otf') format('opentype');
}
@font-face {
  font-family: 'proximanova';
  src: url('./ProximaNova.otf') format('opentype');
}
@font-face {
  font-family: "proxima_nova_rgregular";
  src: url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot");
  src: url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff")
      format("woff"),
    url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf")
      format("truetype"),
    url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova_rgbold";
  src: url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot");
  src: url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.woff")
      format("woff"),
    url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.ttf")
      format("truetype"),
    url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.svg#proxima_nova_rgbold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "HelveticaNeueMedium", sans-serif;
  box-sizing: border-box !important;
}

html {
  min-height: 100%;
}
html,
body {
  overflow-x: hidden;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  background-size: 100%;
  min-height: 100%;
  background-color: #ffffff !important;
  position: relative !important;
}

/* common styles */
a {
  color: white;
  text-decoration: none !important;
  outline: none !important;
}
select:focus {
  outline: none !important;
  box-shadow: none !important;
}

.flex-gap {
  gap: 20px;
}

.flex-gap-col {
  column-gap: 20px;
}

.flex-gap-row {
  row-gap: 20px;
}

a:hover {
  color: white;
}

/* bootstrap overridden styles */
.fc-cprimary {
  color: rgb(60, 182, 211);
}

.bg-cprimary {
  background: #3cb6d3;
}

/* common styles */
.fc-1c {
  color: #1c5663;
}

.fc-1c-muted {
  color: #00000090;
}

.fc-3c {
  color: "#3cb6d3";
}

.transition-3s {
  transition: 0.3s ease all;
}

.transition-3s-width {
  transition: left 0.3s;
  transform-origin: 100%;
}

.b-shadow {
  box-shadow: 0 0.1rem 0.7rem rgb(0 0 0 / 5%);
}

.c-pointer {
  cursor: pointer;
}

/* remove arrows in type=number inputs */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* Bouncing Animation */
@keyframes bouncing {
  from,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.bouncing {
  position: absolute;
  animation-duration: 2s;
  animation-name: bouncing;
  animation-iteration-count: infinite;
}

.text-animation {
  opacity: 0.85;
}

.text-animation:hover {
  opacity: 1;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.location-input:hover,
.location-input:focus,
.location-input:active {
  border-left-color: #3cb6d3 !important;
}

input::-webkit-calendar-picker-indicator {
  display: none !important;
}

.clean-button {
  background-color: transparent;
  border: none;
  outline: none;
}

.clean-button:focus {
  background-color: transparent;
}

.swiper-pagination-fraction {
  background: rgba(28, 86, 99, 0.5);
  color: white;
  border-radius: 6px;
  width: 50px;
  margin: 0 14px;
  padding: 3px 0px;
}

.apartment-details .swiper-pagination-fraction {
  margin-left: 45% !important;
}

.trunc-nline {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: var(--lines);
  -webkit-box-orient: vertical;
  word-break: break-all;
}
