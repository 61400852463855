.custom-input-div-typeform input::placeholder {
  font-size: 16px;
  font-family: proxima-nova;
  word-spacing: 2px;
  color: #00000050;
}

custom-input-div-typeform input:focus {
  border-bottom-color: red !important;
}

.custom-input-div-typeform input {
  
  border-radius: 20px;
  background-color: antiquewhite;
}
