.apartment-card {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.apartment-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.8s cubic-bezier(0.25, 0.45, 0.45, 0.95);
}

.apartment-card img:hover {
  transform: scale(1.02);
}

.apartment-card .icon-size {
  width: 25px;
  height: 25px;
}

.favorite .material-icons {
  font-size: 20px;
}

.btn-controls .material-icons {
  background-color: rgba(255, 255, 255, 0.65);
}

.tag {
  border: 1px solid #3cb6d380;
  border-radius: 20px;
  font-size: 14px;
  margin-right: 10px;
  background-color: #00000003;
}

.tag:first-child {
  margin-left: 0 !important;
}

.maintenance-chip p {
  display: none;
  top: -38px;
  left: -38px;
  font-size: 12px;
}

.maintenance-chip:hover p {
  display: block;
}
