.custom-range::-webkit-slider-thumb {
  background: #3cb6d3 !important;
}

.custom-range::-moz-range-thumb {
  background: #3cb6d3 !important;
}

.custom-range::-ms-thumb {
  background: #3cb6d3 !important;
}
.MuiPopover-paper{
  padding: 7px;
}
