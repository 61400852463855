
.responsiveImage {
    width: 200px; /* Default width for all screen sizes */
  }
  
  @media screen and (min-width: 576px) { /* Small screens (sm) */
    .responsiveImage {
      width: 400px; 
    }
  }
  
  @media screen and (max-width: 575px) { /* Extra small screens (xs) */
    .responsiveImage {
      width: 300px;
      margin-top: 30px;
    }
  }