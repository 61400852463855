.post-property-img-input {
  visibility: hidden;
}

.post-property-img-input::before {
  content: "";
  border: 2px dashed rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  visibility: visible;
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-align: center;
}
.post-property-img-input::after {
  content: "Add Images";
  text-align: center;
  line-height: 40px;
  visibility: visible;
  position: absolute;
  width: 140px;
  font-weight: 500;
  font-size: 16px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #3cb6d3;
  color: white;
  z-index: 1;
  border-radius: 20px;
  cursor: pointer;
}

.input-form-btns {
  border: none;
  padding: 3px 7px;
  border-radius: 5px;
}
.input-form-btns:hover {
  background: #3cb6d3;
}

.post-property-chips {
  width: 140px;
}
