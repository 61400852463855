.loader-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container .loader-inner-div {
  background-color: #3cb6d310;
}

#loader {
  width: 50px;
  height: 50px;
  animation: rotate 2s linear infinite;
}
#loader #loader-circle {
  stroke: #3cb6d3;
  fill: none;
  stroke-width: 5;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
