.community-post-img-inp {
  visibility: hidden;
}

.community-post-img-inp::before {
  content: "";
  border: 1px dashed rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
  visibility: visible;
  position: absolute;
  cursor: pointer;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  text-align: center;
}
.community-post-img-inp::after {
  content: "Upload";
  text-align: center;
  line-height: 40px;
  visibility: visible;
  position: absolute;
  width: 140px;
  font-weight: 500;
  font-size: 16px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #3cb6d3;
  color: white;
  z-index: 1;
  border-radius: 20px;
  cursor: pointer;
}

.post-input {
  border: 1px solid #3cb6d340 !important;
}

.post-input:hover,
.post-input:active,
.post-input:focus {
  border: 1px solid #3cb6d370 !important;
}

.post-input-place {
  border-bottom: 1px solid #3cb6d340 !important;
}

.post-input-place:hover,
.post-input-place:active,
.post-input-place:focus {
  border-bottom: 1px solid #3cb6d370 !important;
}



