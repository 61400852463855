.mySwiper {
  width: 100%;
  height: clamp(250px, 45vh, 350px);
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  position: relative;
}

.swiper-slide-img {
  height: 100%;
  width: 100%;
}

.swiper-slide-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.features-chip {
  width: fit-content;
  height: fit-content;
  border-radius: 99px !important;
}

.features-chip2 {
  background-color: #1c566309;
  width: fit-content;
  height: fit-content;
  border-radius: 99px !important;
}

.apartment-desc span {
  background-color: transparent !important;
  color: #1c5663 !important;
}

.chip-selected {
  background-color: #1c566309;
}

.flatpickr-months .flatpickr-month,
.flatpickr-innerContainer .flatpickr-weekdays,
.flatpickr-innerContainer .flatpickr-weekday,
.flatpickr-days .flatpickr-day.selected {
  background-color: #3cb6d3 !important;
  color: white;
}
