.filter-sidebar {
  /* padding-bottom: 100px !important; */
  overflow-y: auto;
}

.filter-sidebar::-webkit-scrollbar-track {
  background: #3cb6d320;
  border-radius: 6px;
}

.filter-sidebar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.filter-sidebar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #3cb6d3;
}
