.all-chats-container::-webkit-scrollbar-track {
  background: #3cb6d320;
  border-radius: 6px;
}

.all-chats-container::-webkit-scrollbar {
  width: 10px;
}

.all-chats-container::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #3cb6d3;
}

.c {
  box-shadow: rgba(113, 205, 241, 0.4) 2px 2px,
    rgba(107, 195, 253, 0.3) 5px 5px, rgba(101, 187, 228, 0.2) 7px 7px,
    rgba(68, 246, 252, 0.1) 10px 10px, rgba(92, 225, 243, 0.05) 12px 12px;
}
.chatBox {
  min-height: 350;
  border-radius: 10px;
 }

 .all-chats-container{
  height: 245px;
  overflow-x: hidden;
 }
 .all-chats-container::-webkit-scrollbar {
  width: 3px; /* Set width of the scrollbar */
  }


/* Track */
.all-chats-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}
  
/* Handle */
.all-chats-container::-webkit-scrollbar-thumb {
  background: gray; /* Color of the scrollbar handle */
  border-radius: 5px; /* Round the corners of the scrollbar handle */
  opacity: 0.4;
}

/* Handle on hover */
.all-chats-container::-webkit-scrollbar-thumb:hover {
  background: black; /* Color of the scrollbar handle on hover */
  cursor: pointer;
}

  @media(max-width: 431px) {
    .chatBox {
      height: 100vh;
      min-width: 100vw;
      border-radius: 0px;

    }
    .outerBox {
      top: 80px;
    }
    .chatBoxForm{
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
    }
    .all-chats-container{
      height: 100vh;
      overflow-x: hidden;
      overflow-y: auto;
     }
  }
  
  .triangle-container {
    position: absolute;
    /* Adjust this value to position the triangle */
    /* transform: translateY(-50%); */
    transform: rotate(0deg);
    left: -10px;
    top: 0px;
  }
  .triangle-container-user {
    position: absolute;
    /* Adjust this value to position the triangle */
    /* transform: translateY(-50%); */
    transform: rotate(180deg);
    right: -10px;
    bottom: 0px;
  }
  
  .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 20px 0; /* Adjust the width and height of the triangle */
    border-color: transparent #B2FFFF transparent transparent;
  }
  .triangle-user {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 20px 0; /* Adjust the width and height of the triangle */
    border-color: transparent #3cb6d3 transparent transparent;
  }